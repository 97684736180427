import axios from "axios";
export const baseURL = "http://api.lockandkey-eg.com";
// export const baseURL = "http://127.0.0.1:8000";

export const axiosCustom = axios.create({
    baseURL: `${baseURL}`,
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    }
});
export const getMenu = () => axiosCustom.get(`${baseURL}/menu`);