/* eslint-disable */

import { slide as Menu } from "react-burger-menu";
import "./sideMenu.css";
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import logo from '../.././../../assets/logo.png'
import { HashLink } from 'react-router-hash-link';

const SideMenu = (props) => {
  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };
  return (
    <Menu
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
    >
      <div className="side_menu_wrapper">
        <div className="sideMenu_header">
          <Link to='/'><img src={logo} alt='logo'/></Link>
        </div>
        <div className="sideMenu_body">
        <Link onClick={() => handleCloseMenu()} className="menu-item" to="/">
          <span className="nav-text">Home</span>
        </Link>
        <div className="sideMenu_border"/>
        <Link onClick={() => handleCloseMenu()} className="menu-item" to="/menu">
          <span className="nav-text">Menu</span>
        </Link>
        <div className="sideMenu_border"/>
        <Link onClick={() => handleCloseMenu()} className="menu-item" to="/gallery">
          <span className="nav-text">Gallery</span>
        </Link>
        <div className="sideMenu_border"/>
        <HashLink onClick={() => handleCloseMenu()} className="menu-item" to="/#contact-us">
          <span className="nav-text">Contact Us</span>
        </HashLink>
        <div className="sideMenu_border"/>
        <Link onClick={() => handleCloseMenu()} className="menu-item" to="/our-story">
          <span className="nav-text">Our Story</span>
        </Link>
      </div>
      </div>
    </Menu>
  );
};
export default SideMenu;
