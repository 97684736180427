import './menu.css'
import React, {useEffect, useState} from 'react'
import landingBackground from '../../assets/homeBackground.jpg'
import Spinner from 'react-bootstrap/Spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import menu1 from '../../assets/Menu/1.jpg'
import menu2 from '../../assets/Menu/2.jpg'
import menu3 from '../../assets/Menu/3.jpg'
import menu4 from '../../assets/Menu/4.jpg'
import menu5 from '../../assets/Menu/5.jpg'
import menu6 from '../../assets/Menu/6.jpg'
import menu7 from '../../assets/Menu/7.jpg'
import menu8 from '../../assets/Menu/8.jpg'
import menu9 from '../../assets/Menu/9.jpg'
import menu10 from '../../assets/Menu/10.jpg'
import menu11 from '../../assets/Menu/11.jpg'
import menu12 from '../../assets/Menu/12.jpg'
import menu13 from '../../assets/Menu/13.jpg'
import menu14 from '../../assets/Menu/14.jpg'
import menu15 from '../../assets/Menu/15.jpg'
import menu16 from '../../assets/Menu/16.jpg'
import menu17 from '../../assets/Menu/17.jpg'
import menu18 from '../../assets/Menu/18.jpg'
import menu19 from '../../assets/Menu/19.jpg'
import menu20 from '../../assets/Menu/20.jpg'
import menu21 from '../../assets/Menu/21.jpg'
import menu22 from '../../assets/Menu/22.jpg'
import { getMenu } from '../../api'

let menuArr = [
  menu1,menu2,menu3,menu4,menu5,menu6,menu7,menu8,menu9,menu10,
  menu11,menu12,menu13,menu14,menu15,menu16,menu17,menu18,menu19,menu20,
  menu21,menu22
]
const Menu = () => {
  const [menuData, setMenuData] = useState([])
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   getMenu()
  //   .then(res => {
  //     setLoading(false)
  //     setMenuData(res?.data?.menu)
  //   })
  //   .catch(e => {
  //     setLoading(false)
  //   })
  // }, [])
  return (
    <div className='page_wrapper'>
      {loading?
      <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
        <Spinner style={{color:'#fff'}} animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>:
      menuArr?.length === 0?
      <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
        <h5 style={{color:'#fff'}}>We will add the menu soon, Please wait</h5>
      </div>:
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        {menuArr?.map((item, i) => 
          <img key={i} src={item} alt='item?.title'/>
        )}
      </div>
    }
    </div>
  )
}

export default Menu