import React from 'react'
import Gallery from '../home/Gallery/Gallery'
import PageSection from '../ui/PageSection'
const GalleryPage = () => {
  return (
    <>
    <PageSection title='Gallery'/>
    <Gallery isThisAPage={true}/>
    </>
  )
}

export default GalleryPage