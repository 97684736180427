import React from 'react'
import './ContactUs.css'
import emailIcon from '../.././../assets/emailIcon.png'
import phoneIcon from '../.././../assets/phoneIcon.png'
import addressIcon from '../.././../assets/addressIcon.png'
const ContactUs = () => {
  return (
    <section id='contact-us' className='contact_us_section container'>
        <h2>Contact<span style={{padding: '3px', borderBottom: '1px solid #cea353', color: '#cea353'}}>Us</span></h2>
        <div style={{display:'flex', justifyContent: 'center', rowGap: '40px'}} className='row'>
            <div style={{justifyContent:'space-evenly'}} className='contact_us_wrapper row col-lg-9 col-md-12 col-sm-12'>
                <div className='row col-lg-4 col-md-6 col-sm-12'>
                    <div className='info_card col-lg-10 col-md-12 col-sm-12'>
                        <img src={phoneIcon} alt='mobile icon'/>
                        <label>01000000000</label>
                    </div>
                </div>
                <div style={{justifyContent:'center'}} className=' row col-lg-4 col-md-6 col-sm-12'>
                    <div className='info_card col-lg-10 col-md-12 col-sm-12'>
                        <img src={emailIcon} alt='email icon'/>
                        <label>example@example.com</label>
                    </div>
                </div>
                <div style={{justifyContent:'center'}} className=' row col-lg-4 col-md-6 col-sm-12'>
                    <div className='info_card col-lg-10 col-md-12 col-sm-12'>
                        <img src={addressIcon} alt='address icon'/>
                        <label>Maady st</label>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', alignContent:'center', justifyContent:'center', padding:'10px'}}>
                <label style={{marginTop: '30px', fontSize: '17px', fontWeight: '500', borderBottom: '1px solid #cea353', textDecoration: 'unset', color: '#000', padding: '0px 10px 0px 10px'}}>Or, Contact us directly</label>
            </div>
            <form className='row col-lg-9 col-md-12 col-sm-12'>
                <div className='form-group col-lg-6 col-md-12 col-sm-12'>
                    <label>Name</label>
                    <input className='form-control' type='text' placeholder='Your Name'/>
                </div>
                <div className='form-group col-lg-6 col-md-12 col-sm-12'>
                    <label>Email</label>
                    <input className='form-control' type='email' placeholder='example@example.com'/>
                </div>
                <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                    <label>Phone</label>
                    <input className='form-control' type='text' placeholder='Your Phone'/>
                </div>
                <div className='form-group col-lg-12 col-md-12 col-sm-12'>
                    <label>Message</label>
                    <textarea className='form-control' type='text' placeholder='Your Message'/>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}} className='col-lg-12 col-md-12 col-sm-12'>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}} className='col-lg-3 col-md-6 col-sm-12'>
                        <button type='submit' className='contactUsBtn'>
                            Send
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </section>
  )
}

export default ContactUs