import logo from './logo.svg';
import './App.css';
import Home from './components/home/Home';
import Menu from './components/menu/Menu';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from './components/home/Header/Header';
import Footer from './components/home/Footer/Footer';
import GalleryPage from './components/gallery/Gallery';
import AboutUs from './components/aboutus/AboutUs';
function App() {
  return (
    <>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="menu" element={<Menu />} />
          <Route path="gallery" element={<GalleryPage />} />
          <Route path="our-story" element={<AboutUs />} />
        </Routes>
      <Footer/>
    </>
  );
}

export default App;
