import React from 'react'
import outStoryDefaultImg from '../../assets/aboutus.jpeg'

const PageSection = ({imgProp, title}) => {
  return (
    <div style={{minHeight:'400px', display:'flex', alignItems:'center', justifyContent:'center', position: 'relative'}}>
        <img style={{position:'absolute', top:'0px', right: '0px', left: '0px', bottom: '0px', width: '100%', height: '100%', objectFit: 'cover', zIndex:'1', opacity:'0.6'}} src={imgProp || outStoryDefaultImg} alt='section image'/>
        <h1 style={{color:'#fff', textShadow: 'rgb(55 55 55) 2px 2px 8px', zIndex:'2', fontWeight: '800'}}>{title || 'Page Title'}</h1>
    </div>
  )
}

export default PageSection