import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import faceBookIcon from '../.././../assets/facebookIcon.png'
import logo from '../.././../assets/logo.png'
import { useLocation } from 'react-router-dom';
import instaIcon from '../.././../assets/instagramIcon.png'
function Footer() {
    const location = useLocation();
    const [displayOr, setDisplayOr] = useState(true)
    useEffect(() => {
        if (location.pathname === '/menu') {
            setDisplayOr(false)
        } else {
            setDisplayOr(true)
        }
    }, [location.pathname])
  return (
    <>
    {displayOr?
    <footer>
        <img src={logo} className='mainlogo' alt='main logo'/>
        <div className='social_media_wrapper'>
            <Link>
                <img src={faceBookIcon} alt='facebook icon'/>
            </Link>
            <Link>
                <img src={instaIcon} alt='instagram icon'/>
            </Link>
        </div>
        <div className='border_footer'/>
        <label>© 2022 LockandKey. All rights reserved.</label>
    </footer>:null}
    </>
  )
}

export default Footer