import './home.css'
import landingBackground from '../../assets/homeBackground.jpg'
import React from 'react'
import HomePageSlider from './Slider/HomePageSlider'
import Gallery from './Gallery/Gallery'
import Menu from './Menu/Menu'
import OurStory from './OurStory/OurStory'
import ContactUs from './ContactUs/ContactUs'
const Home = () => {
  return (
    <div className='home_page_wrapper'>
        <HomePageSlider/>
        <Gallery isThisAPage={false}/>
        <Menu/>
        <OurStory/>
        <ContactUs/>
    </div>
  )
}

export default Home