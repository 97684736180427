import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../.././../assets/logo.png'
import './Header.css'
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import SideMenu from './sideMenu/SideMenu';
const Header = () => {
    const location = useLocation();
    const [displayOr, setDisplayOr] = useState(true)
    useEffect(() => {
        if (location.pathname === '/menu') {
            setDisplayOr(false)
        } else {
            setDisplayOr(true)
        }
    }, [location.pathname])
  return (
    <>
    {displayOr?
    <header className='row'>
    <div className='header_logo col-lg-1 col-md-1 col-sm-12'>
        <Link to='/'><img src={logo} alt='logo'/></Link>
    </div>
    <div className='side_menu_wraaper'>
        <SideMenu/>
    </div>
    <nav className='desktopView col-lg-10 col-md-10 col-sm-12 row'>
        <ul className='col-lg-8 col-md-12 col-sm-12'>
            <li className=''>
                <Link to='/'>Home</Link>
            </li>
            <li className=''>
                <Link to='/menu'>Menu</Link>
            </li>
            <li className=''>
                <Link to='/gallery'>Gallery</Link>
            </li>
            <li className=''>
                <Link to='/our-story'>Our Story</Link>
            </li>
            <li className=''>
                <HashLink to='/#contact-us'>Contact Us</HashLink>
            </li>
        </ul>
    </nav>
</header>:null}
    </>
    
  )
}

export default Header