import React, {useEffect, useState} from 'react'
import './Menu.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, A11y } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import menu1 from '../.././../assets/1.jpg'
import menu2 from '../.././../assets/2.jpg'
import menu3 from '../.././../assets/3.jpg'
import menu4 from '../.././../assets/4.jpg'
import menu5 from '../.././../assets/5.jpg'
import menu6 from '../.././../assets/6.jpg'
import { Link } from 'react-router-dom';

const Menu = () => {
    const [view, setView] = useState(3)
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    useEffect(()=> {
        if (window.innerWidth > 3400 && window.innerWidth <= 4000) {
            setView(3)
        } else if (window.innerWidth > 2530 && window.innerWidth <= 3400) {
            setView(3)
        } else if (window.innerWidth > 1860 && window.innerWidth <= 2530) {
            setView(3)  
        } else if (window.innerWidth > 1600 && window.innerWidth <= 1860) {
            setView(3)
        } else if (window.innerWidth > 1275 && window.innerWidth <= 1600) {
            setView(3)
        } 
        else if (window.innerWidth > 1000 && window.innerWidth <= 1300) {
            setView(3)
        } else if (window.innerWidth > 800 && window.innerWidth <= 1000) {
            setView(2.2)
        } else if (window.innerWidth > 700 && window.innerWidth <= 800) {
            setView(1.8) 
        } else if (window.innerWidth > 600 && window.innerWidth <= 700) {
            setView(1.5)
        } else if (window.innerWidth > 500 && window.innerWidth <= 600) {
            setView(1.5)
        } else if (window.innerWidth > 330 && window.innerWidth <= 500) {
            setView(1)
        } else if (window.innerWidth > 190 && window.innerWidth <= 330) {
            setView(1)
        } else {
            setView(1)
        }
      }, [width])
  return (
    <section className='menu_section container'>
        <h2>Our New <span style={{padding: '3px', borderBottom: '1px solid #cea353', color: '#cea353'}}>Menu</span></h2>
        <div className='menu_wrapper row'>
            <Swiper
                modules={[Navigation, A11y]}
                slidesPerView={view}
                loop
                navigation
            >
                <SwiperSlide className='menu_slider'>
                    <Link to='/menu'><img className='menu_slider_img' src={menu1} alt='menu1'/></Link>
                </SwiperSlide>
                <SwiperSlide className='menu_slider'>
                    <Link to='/menu'><img className='menu_slider_img' src={menu2} alt='menu2'/></Link>
                </SwiperSlide>
                <SwiperSlide className='menu_slider'>
                    <Link to='/menu'><img className='menu_slider_img' src={menu3} alt='menu3'/></Link>
                </SwiperSlide >
                <SwiperSlide className='menu_slider'>
                    <Link to='/menu'><img className='menu_slider_img' src={menu4} alt='menu4'/></Link>
                </SwiperSlide>
                <SwiperSlide className='menu_slider'>
                    <Link to='/menu'><img className='menu_slider_img' src={menu5} alt='menu5'/></Link>
                </SwiperSlide>
                <SwiperSlide className='menu_slider'>
                    <Link to='/menu'><img className='menu_slider_img' src={menu6} alt='menu6'/></Link>
                </SwiperSlide>
    
            </Swiper>
        </div>
        <Link to='/menu' style={{alignSelf: 'center', marginTop: '30px', fontSize: '17px', fontWeight: '500', borderBottom: '1px solid #cea353', textDecoration: 'unset', color: '#000', padding: '0px 10px 0px 10px'}}>View Full Menu</Link>
    </section>
  )
}

export default Menu