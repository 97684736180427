import React from 'react'
import { Link } from 'react-router-dom'
import outStoryDefaultImg from '../.././../assets/aboutus.jpeg'
import './OurStory.css'
const OurStory = () => {
  return (
    <section className='our_story_section container'>
        <h2>Our <span style={{padding: '3px', borderBottom: '1px solid #cea353', color: '#cea353'}}>Story</span></h2>
        <div className='our_story_wrapper row'>
            <div className='col-lg-5 col-md-12 col-sm-12'>
                <div style={{display:'flex', alignItems: 'center'}}>
                    <div style={{width: '50%', borderBottom: '5px solid #cea353', marginBottom: '8px', borderRadius: '3px'}}></div>
                </div>
                <img src={outStoryDefaultImg} alt='our story image'/>
                <div style={{display:'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <div style={{width: '50%', borderBottom: '5px solid #cea353', marginTop: '8px', borderRadius: '3px'}}></div>
                </div>
            </div>
            <div className='col-lg-5 col-md-12 col-sm-12'>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
            </div>
        </div>
        <Link to='/our-story' style={{alignSelf: 'center', marginTop: '30px', fontSize: '17px', fontWeight: '500', borderBottom: '1px solid #cea353', textDecoration: 'unset', color: '#000', padding: '0px 10px 0px 10px'}}>Read More</Link>

    </section>
  )
}

export default OurStory