import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, A11y } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import sliderDefault from '../.././../assets/homeBackground.jpg'
import './HomePageSlider.css'

const HomePageSlider = () => {
  return (
    <Swiper
      modules={[Navigation, A11y]}
      slidesPerView={1}
      loop
      navigation
    >
      <SwiperSlide>
        <img className='slider_img' src={sliderDefault} alt='ad1'/>
      </SwiperSlide>
      <SwiperSlide>
        <img className='slider_img' src={sliderDefault} alt='ad1'/>
      </SwiperSlide>

    </Swiper>

  )
}

export default HomePageSlider